import { Component } from '@angular/core';
import { PageUtilBaseComponent } from '../page-util.component';

@Component({
  selector: 'page-maintenance',
  templateUrl: './page-maintenance.component.html',
  styleUrl: './page-maintenance.component.less'
})
export class PageMaintenanceComponent extends PageUtilBaseComponent {
  
}
