<nz-layout>
  <nz-sider nzCollapsible [nzCollapsed]="navCollapsed" [nzTrigger]="null" *ngIf="navVisible()">
    <pj-layout-navigation></pj-layout-navigation>
  </nz-sider>
  <nz-layout>
    <nz-header [ngClass]="getHeaderCSSClass()" *ngIf="headerVisible()">
      <pj-layout-header></pj-layout-header>
    </nz-header>
    <nz-content [ngClass]="getContentCSSClass()">
      <router-outlet></router-outlet>
    </nz-content>
    <nz-footer [ngClass]="getHeaderCSSClass()" *ngIf="footerVisible()">
      <pj-layout-footer></pj-layout-footer>
    </nz-footer>
  </nz-layout>
</nz-layout>