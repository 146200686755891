import { Component } from '@angular/core';
import { LayoutElmName } from '../app-layout.service';
import { PjLayoutComponent } from '../pj-layout.component';

@Component({
  selector: 'pj-layout-header',
  template: `<div></div>`
})
export class PjLayoutHeaderComponent extends PjLayoutComponent {

  protected override elmName(): string {
    return LayoutElmName.HEADER;
  }

}
