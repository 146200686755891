import { Component } from '@angular/core';
import { ReactiveFormsModule,Validators } from '@angular/forms';
import { NzFormControlComponent,NzFormItemComponent } from 'ng-zorro-antd/form';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { environment } from 'src/environments/environment';
import { PjTextInputModule } from "../../../component-form/pj-text-input/pj-text-input.module";
import { PjVerificationCodeModule } from "../../../component-form/pj-verification-code/pj-verification-code.module";
import { PjButtonModule } from "../../../component/pj-button/pj-button.module";
import { PjIconModule } from "../../../component/pj-icon/pj-icon.module";
import { PageLoginBaseComponent } from '../page-login-base.component';

@Component({
  selector: 'page-login-by-phone-number',
  standalone: true,
  imports: [ReactiveFormsModule,NzTypographyModule,PjButtonModule,PjIconModule,NzFormItemComponent,PjTextInputModule,NzFormControlComponent,PjVerificationCodeModule],
  templateUrl: './page-login-by-phone-number.component.html',
  styleUrl: './page-login-by-phone-number.component.less'
})
export class PageLoginByPhoneNumberComponent extends PageLoginBaseComponent {

  constructor() {
    super();
    this.validateForm = this._fb.group({
      phoneNumber: [environment.hardCodePhoneNumber,[Validators.required]],
      verifyCode: [environment.hardCodeVerificationCode,[Validators.required]],
      // remember: [true]
    });
  }
  protected override getLoginName (): string {
    return '';
  }

  // protected override getPhoneNumber (): string {
  //   return this.validateForm.get('phoneNumber')?.value ?? '';
  // }

  onAccountLoginClick () {
    this._routingService.goToAccountPasswordLoginPage();
  }

  override submitForm (): void {
    const { loginName,phoneNumber } = this.getUserLoginInfo();
    if (this.validateForm.valid) {
      const v = this.validateForm.value;
      this._authService.login(
        {
          phone: phoneNumber,
          password: v.password,verifyCode: v.verifyCode
        }
      ).subscribe(res => {
        if (res != null && res.id != null) {
          const casUser = res;
          this._storageService.setCasToken(casUser?.token);
          this._routingService.gotoUrlAfterLogin(this._redirectUrl);
        }
      });
    } else {
      console.error(this.validateForm.value);
    }
  }
}
