<div [ngClass]="{'full-container':showContainer,'hide':!showContainer }">
  <div class="reader-container">
    <div id="reader">
      <ngx-scanner-qrcode *ngIf="scanService.setupScanner" #action="scanner" [config]="config"
        (event)="onEvent($event, action)"></ngx-scanner-qrcode>
    </div>
  </div>
  <pj-icon-button *ngIf="showContainer" class="back-btn" btnType="text" size="large" iconName="arrow_back_ios"
    (click)="onBackBtnClick()">
  </pj-icon-button>
</div>
