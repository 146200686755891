import { registerLocaleData } from '@angular/common';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import zh from '@angular/common/locales/zh';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NZ_I18N, zh_CN } from 'ng-zorro-antd/i18n';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { AppLayoutModule } from 'src/layout/app-layout.module';
import { PageAuthModule } from 'src/page/page-auth/page-auth.module';
import { PageUtilModule } from 'src/page/page-util/page-util.module';
import { AppComponent } from './app.component';

export function httpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}
registerLocaleData(zh);
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),

    NzTypographyModule,

    PageAuthModule,

    PageUtilModule,

    AppLayoutModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: zh_CN },
    provideHttpClient(),
    provideAnimationsAsync(),
    // {
    //   provide: ErrorHandler,
    //   useClass: AppGlobalErrorhandler
    // }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
