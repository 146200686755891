import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PjAction, PjActionEvent } from '../component.type';
import { PjTabGroupBaseComponent } from './pj-tab-group-base.component';
import { PjTabItemData } from './pj-tab-item.type';

@Component({
  selector: 'pj-tab-group',
  templateUrl: './pj-tab-group.component.html',
  styleUrl: './pj-tab-group.component.less',
})
export class PjTabGroupComponent extends PjTabGroupBaseComponent {
  @Input()
  actionItems?: PjAction[];

  @Input()
  position: 'top' | 'left' = 'top';

  @Output()
  actionEvent: EventEmitter<PjActionEvent> = new EventEmitter<PjActionEvent>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['tabItems'] != null && this.tabItems != null) {
      this._setActivedIndex();
    }
  }
  private _activedIndex: number = 0;

  override onTabItemClick(tabItem: PjTabItemData): void {
    this._setActivedIndex(tabItem);
  }

  onTabClose(index: number): void {
    this.tabItems.splice(index, 1);
  }

  getActiveIndex(): number {
    return this._activedIndex;
  }

  private _setActivedIndex(activeItem?: PjTabItemData): void {
    const idx = this.tabItems.findIndex((t) => t.title === activeItem?.title);
    if (idx !== -1) {
      this._activedIndex = idx;
    } else {
      const isActivedIdx = this.tabItems.findIndex((t) => t.isActived);
      if (isActivedIdx) {
        this._activedIndex = isActivedIdx;
      }
    }
  }

  onActionBtnClick(action: PjAction): void {
    if (action.actionString != null) {
      this.actionEvent.emit({ actionString: action.actionString });
    }
  }
}
