import { Type } from "@angular/core";

export interface Environment {
  // 首页路由对用的模块
  projectModule: Type<any> | Type<any>[];
  // 项目首页路由
  mainComponentPath: string;

  // 是否生产环境
  production: boolean;

  // 是否显示调试信息(console.log)
  enableLog: boolean;

  // 是否需要页脚
  enableFooter: boolean;
  // 是否允许匿名用户使用
  enableAnonymous: boolean;

  // 是否需要确认是人工操作
  enableCaptcha: boolean;
  // 本地存储是不是使用gzip压缩
  enableGZipForLocalStorage: boolean;
  // 是不是允许组件文档，在生产环境禁止组件文档比较好
  enablePolarJComponentDoc: boolean;

  // 是否使用基于元数据的数据管理功能
  enableMetaDataManager: boolean;
  logo: string;

  // 登录方式： 电子邮件email/电话号码phone/其他第三方third-party
  loginMethod: LoginMethod;

  // 以下是为了开发方便，在开发环境可以自动填写的登录信息
  hardCodeEmail?: string;
  hardCodePhoneNumber?: string;
  hardCodePassword?: string;
  hardCodeVerificationCode?: string;
}

export enum LoginMethod {
  EMAIL, PHONE, THIRD_PARTY
}