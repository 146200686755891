<div class="flex flex-col items-center gap-2 justify-center">
  <div class="p-4 inline-flex">
    <img style="width: 382px" [src]="getImageUrl()" />
  </div>
  <div class="flex flex-col gap-4 p-8 shadow-lg border-solid rounded-lg border">
    <h3 class="text-2xl font-bold self-start">{{getTitle()}}</h3>
    <form nz-form [formGroup]="validateForm" class="flex flex-col gap-2 w-[320px]">
      <nz-form-item>
        <nz-form-control nzErrorTip="{{ 'auth.error.login.username.required' | translate }}">
          <pj-text-input iconName="person" placeHolder="Username" formControlName="username">
          </pj-text-input>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="{{ 'auth.error.login.password.required' | translate }}">
          <pj-text-input [isPassword]="true" iconName="lock" placeHolder="Password" formControlName="password">
          </pj-text-input>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="{{ 'auth.error.login.verifycode.required' | translate}}">
          <pj-verification-code formControlName="verifyCode" (codeRequest)="onFetchVerificationCodeBtnClick()">
          </pj-verification-code>
        </nz-form-control>
      </nz-form-item>
      <div class="flex flex-row items-center justify-between">
        <pj-checkbox [checkItem]="{label: 'auth.login.rememberme' | translate, value: '', selected: true}">
        </pj-checkbox>
        <pj-std-button btnType="link" label="{{ 'auth.login.forgotpassword' | translate}}"
          (click)="onForgotPasswordBtnClick()">
        </pj-std-button>
      </div>
      <pj-std-button class="self-center w-1/2" label="{{ 'auth.login.loginBtn' | translate}}" btnType="filled"
        (click)="submitForm()">
      </pj-std-button>
      <div class="self-center flex flex-row items-center">
        <pj-std-button btnType="link" label="{{ 'auth.login.signup' | translate}}" (click)="onRegisterBtnClick()">
        </pj-std-button>
        <pj-std-button btnType="link" label="{{ 'auth.login.phonelogin' | translate}}"
          (click)="onVerificationCodeLoginClick()">
        </pj-std-button>
      </div>
    </form>
  </div>
</div>
