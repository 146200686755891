import { Component } from '@angular/core';
import { PageUtilBaseComponent } from '../page-util.component';

@Component({
  selector: 'page-error-404',
  templateUrl: './page-error-404.component.html',
  styleUrl: './page-error-404.component.less'
})
export class PageError404Component extends PageUtilBaseComponent {

}
