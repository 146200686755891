import { inject } from '@angular/core';
import { ActivatedRouteSnapshot,CanActivateFn,CanDeactivateFn,Router,RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { APPCONSTANT } from 'src/app/app.constant';
import { HelperService } from './helper.service';
import { PathName } from './routing.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { LoginMethod } from 'src/environments/environment.interface';

export const activeGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot, state: RouterStateSnapshot
) => {
  const storageService = inject(StorageService);
  const router = inject(Router);
  const curToken: string = storageService.getCasToken();
  let redirectUrl = state.url || '';
  if (!!curToken) {
    return true;
  }
  let loginUrl = `/${PathName.LOGIN_BY_PHONE_NUMBER}`;
  if(environment.loginMethod == LoginMethod.EMAIL) {
    loginUrl =  `/${PathName.LOGIN}`;
  }
  if (redirectUrl.length > 0) {
    loginUrl = `${loginUrl}?${APPCONSTANT.URL_PARAMETER.REDIRECT_URL}=${HelperService.encodeToBase64(redirectUrl)}`;
  }
  return router.parseUrl(loginUrl);
};


export const deactivateGuard: CanDeactivateFn<any> = (
  component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot
): Observable<boolean> | boolean => {
  const storageService = inject(StorageService);
  let path = currentState.url;
  storageService.setLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LAST_USED_URL, path);
  return true;
}
