import { Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { StorageService } from 'src/service/storage.service';
import { languageConfig } from '../environments/language';
import { version } from '../environments/version';
import { APPCONSTANT } from './app.constant';
import { ThemeService } from './theme.service';

@Component({
  selector: 'app-root',
  template: APPCONSTANT.LayoutSelector,
  styleUrls: []
})
export class AppComponent {

  private _translateService: TranslateService = inject(TranslateService);
  private _storageService: StorageService = inject(StorageService);
  constructor(private _titleService: Title, private _themeService: ThemeService) {
    let usedLanguage: string = this._getUsedLanguage();
    moment.locale(usedLanguage);
    let coreLang = usedLanguage + '.core';
    let projectLang = usedLanguage + '.project';
    this._translateService.addLangs([coreLang, projectLang]);
    this._translateService.setDefaultLang(coreLang);
    this._translateService.use(projectLang);
    this._translateService.onLangChange.subscribe(() =>
      this._titleService.setTitle(this._translateService.instant("app.browserTitle") + ": " + version.buildSn)
    );
  }
  ngOnInit(): void {
    this._themeService.loadTheme().then();
  }

  private _getUsedLanguage(): string {
    let l = this._storageService.getLocalItem(APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.SELECTED_LANGUAGE);
    if (l == null) {
      l = this._translateService.getBrowserLang();
    }
    let usedLanguage: string = languageConfig.defaultLanguage;
    languageConfig.supportedLanguages.forEach(lang => {
      if (lang.startsWith(l)) {
        usedLanguage = lang;
      }
    });
    return usedLanguage;
  }
}
