import { Observable } from "rxjs";
import { BackendAttachment } from "./generated/attachment.backend.model";
import { BackendFileInfo } from "./generated/file-info.backend.model";

export class Attachment extends BackendAttachment {
  declare fileInfo?: FileInfo;
  getAttachmentById?: (id: number) => Observable<Attachment>;
}

export class FileInfo extends BackendFileInfo {


  blobContent?: File;
}
