import { Component, ComponentRef, inject } from '@angular/core';
import { filter, map } from 'rxjs';
import { PJ_ACTION, PjActionEvent } from 'src/component/component.type';
import { AppLayoutConfig } from './app-layout.config';
import { AppLayoutService, LayoutElmName } from './app-layout.service';
import { PjLayoutHeaderBaseComponent } from './pj-layout-header/pj-layout-header-base.component';
import { PjLayoutNavBaseComponent } from './pj-layout-navigation/pj-layout-nav-base.component';

@Component({ template: `` })
export abstract class AppLayoutBaseComponent {
  navCollapsed: boolean = false;

  private _navComponent?: PjLayoutNavBaseComponent;
  private _layoutService: AppLayoutService = inject(AppLayoutService);
  protected _layoutConfig?: AppLayoutConfig;

  ngOnInit(): void {
    this._layoutService.layoutConfig().pipe(
    ).subscribe(layoutCfg => {
      this._layoutConfig = layoutCfg;
    });
    this._layoutService.layoutElmChanged().pipe(
      filter(elmName => elmName == LayoutElmName.HEADER),
      map(elmName => this._layoutService.getLayoutComponent(elmName)),
      filter((headerCmp: ComponentRef<PjLayoutHeaderBaseComponent> | null) => headerCmp != null)
    ).subscribe(headerCmp => {
      headerCmp?.instance.headerActionEvent.subscribe(actionEvent => this._headerActionEventHandler(actionEvent));
    });

    this._layoutService.layoutElmChanged().pipe(
      filter(elmName => elmName == LayoutElmName.NAVBAR),
      map(elmName => this._layoutService.getLayoutComponent(elmName)),
      filter((navCmp: ComponentRef<PjLayoutNavBaseComponent> | null) => navCmp != null)
    ).subscribe(navCmp => {
      this._navComponent = navCmp?.instance;
      navCmp?.instance.navActionEvent.subscribe(actionEvent => this._navActionEventHandler(actionEvent));
    });
  }

  navVisible(): boolean {
    return this._layoutConfig?.navigationVisible || false;
  }

  headerVisible(): boolean {
    return this._layoutConfig?.headerVisible || false;
  }

  footerVisible(): boolean {
    return this._layoutConfig?.footerVisible || false;
  }

  private _headerActionEventHandler(actionEvent: PjActionEvent): void {
    switch (actionEvent.actionString) {
      case PJ_ACTION.COLLAPSE:
        this.navCollapsed = actionEvent.para!['collapsed'];
        this._navComponent?.toggleNav(this.navCollapsed);
        break;
    }
  }

  private _navActionEventHandler(actionEvent: PjActionEvent): void {
  }
}