import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'pj-text-input',
  templateUrl: './pj-text-input.component.html',
  styleUrl: './pj-text-input.component.less',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PjTextInputComponent),
      multi: true,
    },
  ],
})
export class PjTextInputComponent implements ControlValueAccessor {
  @Input()
  ngModelOptions: {
    name?: string;
    standalone?: boolean;
    updateOn?: any;
  } = {};

  @Input()
  size: 'large' | 'default' | 'small' = 'large';
  @Input()
  disabled: boolean = false;

  @Input()
  iconName: string = '';

  @Input()
  placeHolder: string = '';

  @Input()
  isPassword: boolean = false;

  @Input()
  scannable: boolean = false;

  @Input()
  textValue: string = '';

  @Input()
  numberOnly: boolean = false;

  @Input()
  disableAutoPanel = false;

  @Output()
  valueChange: EventEmitter<string> = new EventEmitter();

  @Output()
  enterkeyPressed: EventEmitter<void> = new EventEmitter();

  @Output()
  lostFocus: EventEmitter<string> = new EventEmitter();

  @Output()
  focus: EventEmitter<string> = new EventEmitter();

  @Output()
  scanEmitter = new EventEmitter();

  @Output()
  prefixIconEmitter = new EventEmitter();
  // private _scanner?: BarcodeAndQrScannerService;
  constructor(private _injector: Injector) {}

  private _emitEnterKeyEvent: EventEmitter<void> = new EventEmitter();
  ngOnInit(): void {
    // if (this.scannable) {
    // this._scanner = this._injector.get(BarcodeAndQrScannerService);
    //   this._scanner.scanResult().subscribe(result => {
    //     if (result?.scanResult != null && result?.scanResult.length > 0) {
    //       this.textValue = result.scanResult;
    //       this._isScanning = false;
    //     }
    //     console.log(result.errMessage);
    //   });
    // }
    this._emitEnterKeyEvent.pipe(debounceTime(500)).subscribe(() => {
      this.enterkeyPressed.emit();
    });
  }
  onNgModelChange(value: string): void {
    this.textValue = value;
    if (this._onChanged != null) {
      this._onChanged(value);
    }
    this.valueChange.emit(value);
  }

  onKeyPress(event: KeyboardEvent): void {
    if (event.key == 'Enter') {
      this._emitEnterKeyEvent.emit();
    }
  }

  onBlur(): void {
    if (this._onTouched) {
      this._onTouched();
    }
    this.lostFocus.emit(this.textValue);
  }

  getInputType(): string {
    let inputType: string = 'text';
    if (this.isPassword) {
      inputType = 'password';
    } else {
      if (this.numberOnly) {
        inputType = 'number';
      }
    }
    return inputType;
  }

  private _isScanning: boolean = false;
  onScanBtnClick(): void {
    this.scanEmitter.emit();

    // if (this._isScanning) {
    //   this._isScanning = false;
    //   this._scanner?.cancelScan();
    // } else {
    //   this._isScanning = true;
    //   this.scanEmitter.emit();
    //   this._scanner?.scan();
    // }
  }

  private _onChanged?: Function;
  private _onTouched?: Function;
  writeValue(obj: string): void {
    this.textValue = obj;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnChange(fn: any): void {
    this._onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  onFocus() {
    this.focus.emit();
  }

  onPrefixIconClick() {
    this.prefixIconEmitter.emit();
  }
}
