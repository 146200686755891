import { Component, inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APPCONSTANT } from 'src/app/app.constant';
import { StorageService } from 'src/service/storage.service';
import { PjPageComponent } from '../page-component';

@Component({ template: `` })
export abstract class PageRegisterBaseComponent extends PjPageComponent {

  protected _routInfo: ActivatedRoute = inject(ActivatedRoute);
  protected _storageService = inject(StorageService);

  protected _redirectUrl: string = '';

  protected _fb: FormBuilder = inject(FormBuilder);

  override ngOnInit(): void {
    super.ngOnInit();
    this._routInfo.queryParams.subscribe(params => {
      this._redirectUrl = params[APPCONSTANT.URL_PARAMETER.REDIRECT_URL];
    });
  }

  getImageUrl(): string | undefined {
    return;
  }

  submitForm(): void {
  }

  getTitle(): string {
    return this._translateService.instant('auth.register.title');
  }
}
