import { Component, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APPCONSTANT } from 'src/app/app.constant';
import { PjVerificationCodeComponent } from 'src/component-form/pj-verification-code/pj-verification-code.component';
import { PathName } from 'src/service/routing.service';
import { StorageService } from 'src/service/storage.service';
import { PageAuthBaseComponent } from './page-auth-base.component';

@Component({ template: `` })
export abstract class PageLoginBaseComponent extends PageAuthBaseComponent {
  protected _routInfo: ActivatedRoute = inject(ActivatedRoute);
  protected _storageService = inject(StorageService);

  protected _redirectUrl: string = '';

  private _verifyCodeSended = false;
  protected _fb: FormBuilder = inject(FormBuilder);

  validateForm: FormGroup= this._fb.group({});

  @ViewChild(PjVerificationCodeComponent)
  pjVerificationCodeComponent?: PjVerificationCodeComponent;

  override ngOnInit (): void {
    super.ngOnInit();
    this._routInfo.queryParams.subscribe(params => {
      this._redirectUrl = params[APPCONSTANT.URL_PARAMETER.REDIRECT_URL];
    });
  }

  getImageUrl (): string | undefined {
    return;
  }

  submitForm (): void {
    const { loginName,phoneNumber } = this.getUserLoginInfo();
    if (this.validateForm.valid) {
      const v = this.validateForm.value;
      this._authService.login(
        {
          username: loginName,
          phone: phoneNumber,
          password: v.password,verifyCode: v.verifyCode
        }
      ).subscribe(res => {
        if (res != null && res.id != null) {
          const casUser = res;
          this._storageService.setCasToken(casUser?.token);
          this._routingService.gotoUrlAfterLogin(this._redirectUrl);
        }
      });
    } else {
      console.error(this.validateForm.value);
    }
  }

  onForgotPasswordBtnClick(): void {
    this._routingService.gotoUrl(PathName.FORGOTPASSWORD);
  }

  onRegisterBtnClick(): void {
    this._routingService.gotoUrl(PathName.REGISTER);
  }
  getTheValidateStatus (): string {
    return this.validateForm.controls['verifyCode'].valid ? 'sccess' : 'error';
  }

  getTitle (): string {
    return this._translateService.instant('auth.login.title') + this._translateService.instant('app.system');
  }

  // getVerificationCodeDisabled (): boolean {
  //   return this._verifyCodeSended || this.validateForm.controls['phone']?.valid;
  // }

  onFetchVerificationCodeBtnClick (): void {
    const disabled = this.pjVerificationCodeComponent?.isBtnDisabled();
    if (disabled) {
      return;
    }
    const { loginName,phoneNumber } = this.getUserLoginInfo();
    if (!phoneNumber) {
      this._messageService.warning('请输入手机号！');
      return;
    }
    this._authService.verifyCodeRequest({
      phone: phoneNumber
    }
    ).subscribe(res => {
      if (res) {
        console.log('Sending Verification Code.');
        this._verifyCodeSended = true;
        this.pjVerificationCodeComponent?.startCountDown();
      } else {
        console.log('Can not send Verification Code.');
      }
    });
  }
  protected abstract getLoginName (): string;

  protected getUserLoginInfo (): {
    phoneNumber?: string,
    loginName?: string,
  } {
    const phoneNumber = this.validateForm.get('phoneNumber')?.value ?? '';
    const loginName = this.getLoginName();
    const nameNotValid = loginName == null || loginName.length == 0;
    const phoneNumberNotValid = phoneNumber == null || phoneNumber.length == 0;

    const isNotValid = [nameNotValid,phoneNumberNotValid].every(i => i);
    if (isNotValid) {
      return {};
    } else {
      return {
        phoneNumber,
        loginName
      };
    }
  }
}
