<div class="flex flex-col items-center gap-2 justify-center">
  <div class="p-4 inline-flex">
    <img style="width: 382px" [src]="getImageUrl()" />
  </div>
  <div class="flex flex-col gap-4 p-8 shadow-lg border-solid rounded-lg border">
    <h3 class="text-2xl font-bold self-start">{{getTitle()}}</h3>
    <form nz-form [formGroup]="validateForm" class="flex flex-col gap-2 w-[320px]">
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your Password!">
          <pj-text-input [isPassword]="true" iconName="lock" placeHolder="Password" formControlName="password">
          </pj-text-input>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your verification code!" [nzValidateStatus]="getTheValidateStatus()">
          <pj-verification-code formControlName="verifyCode" (codeRequest)="onFetchVerificationCodeBtnClick()">
          </pj-verification-code>
        </nz-form-control>
      </nz-form-item>
      <pj-std-button class="self-center w-1/2" label="Log in" btnType="filled" (click)="submitForm()"></pj-std-button>
    </form>
  </div>
</div>