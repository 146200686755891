import { Component } from '@angular/core';
import { AppLayoutBaseComponent } from '../app-layout-base.component';


@Component({
  selector: 'app-layout-vertical',
  templateUrl: './app-layout-vertical.component.html',
  styleUrl: './app-layout-vertical.component.less'
})
export class AppLayoutVerticalComponent extends AppLayoutBaseComponent {

  getHeaderCSSClass(): string {
    let cssClass = '';
    if (this._layoutConfig?.navigationVisible) {
      cssClass = this.navCollapsed ? 'nav-collapsed' : 'nav-uncollapsed';
    }
    return cssClass;
  }

  getContentCSSClass(): string {
    let contentClass = '';
    if (this._layoutConfig?.navigationVisible) {
      contentClass = this.navCollapsed ? 'nav-collapsed' : 'nav-uncollapsed';
    } else {
      contentClass = 'withoutNav';
    }
    if (this._layoutConfig?.headerVisible) {
      contentClass = contentClass + (this._layoutConfig?.footerVisible ? ' withFooter' : ' withoutFooter');
    } else {
      contentClass = contentClass + ' withoutHeader' + (this._layoutConfig?.footerVisible ? ' withFooter' : ' withoutHeaderAndFooter');
    }
    return contentClass;
  }

}
