import { Injectable } from '@angular/core';
import { escape, unescape } from "lodash";
import moment from 'moment';
import { FileInfo } from 'src/model/attachment.model';

@Injectable({ providedIn: 'root' })
export class HelperService {
  static OneDayMS: number = 24 * 60 * 60 * 1000;

  static stringToEntityId(str?: string): number {
    if (str == null) {
      return -1;
    }
    let a = Number.parseInt(str);
    if (a == null || Number.isNaN(a)) {
      return -1;
    }
    return a;
  }
  static isMobile(): boolean {
    let a = ('ontouchstart' in document.documentElement);
    return a;
  }

  static getLongTimeForDayOfDate(d: Date): number {
    let d1: Date = new Date(d.getFullYear(), d.getMonth(), d.getDate());
    return d1.getTime();
  }

  static formatDateTime(di?: Date | string, fmt?: string): string {

    let d = moment(di);
    let res: string;
    if (fmt == null) {
      res = d.format('YYYY年MM月DD日 HH:mm');
    } else {
      res = d.format(fmt);
    }
    return res;
  }

  static compareToday(d: Date): number {
    let td = new Date();
    if (td.getFullYear() > d.getFullYear()) {
      return -1;
    } else if (td.getFullYear() < d.getFullYear()) {
      return 1;
    }
    if (td.getMonth() > d.getMonth()) {
      return -1;
    } else if (td.getMonth() < d.getMonth()) {
      return 1;
    }
    if (td.getDate() > d.getDate()) {
      return -1;
    } else if (td.getDate() < d.getDate()) {
      return 1;
    }
    return 0;
  }
  static isDate(data: any): boolean {
    let a = Object.prototype.toString.call(data);
    return a == '[object Date]';
  }
  static isPrimitive(data: any): boolean {
    const primitiveTypes = ['String', 'Number', 'Boolean', 'Symbol', 'Undefined', 'Null'];
    let a = Object.prototype.toString.call(data);
    let b = false;
    primitiveTypes.forEach(t => {
      b = b || a.includes(t);
    });
    return b;
  }

  static isBoolean(data: any): boolean {
    let a = Object.prototype.toString.call(data);
    return a.includes('Boolean');
  }
  static decodeFromBase64(base64String: string): string {
    return unescape(atob(base64String));
  }

  static encodeToBase64(str: string): string {
    return escape(btoa(str));
  }

  static booleanValue(b?: boolean): boolean {
    if (b == null || !HelperService.isBoolean(b)) {
      return false;
    }
    return b;
  }

  static hasStringValue(str?: string): boolean {
    return str != null && str.length > 0;
  }

  static arrayIsEmpty(arr?: any[]): boolean {
    if (arr == null) {
      return true;
    }
    if (!Array.isArray(arr)) {
      return true;
    }
    return arr.length == 0;
  }

  static arrayIsNotEmpty(arr?: any[]): boolean {
    return !HelperService.arrayIsEmpty(arr);
  }

  static showPdfInFileInfoOnPage(fileInfo?: FileInfo): void {
    if (fileInfo == null || fileInfo.base64ContentForFrontend == null || fileInfo.originalFileName == null) {
      return;
    }
    let a = HelperService.decodeFromBase64(fileInfo.base64ContentForFrontend);
    let b: Uint8Array = Uint8Array.from(Array.from(a).map(letter => letter.charCodeAt(0)));
    const blob = new File([b], fileInfo.originalFileName, { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }

  static resizeImageTo200K(): void {

  }

  private static base64Flag = 'data:image/png;base64,';
  static attachBase64Flag(base64Content?: string): string | undefined {
    if (base64Content == null) {
      return;
    }
    if (base64Content.startsWith('data:image')) {
      return base64Content;
    }
    return HelperService.base64Flag + base64Content;
  }
}
