import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-horizontal',
  templateUrl: './app-layout-horizontal.component.html',
  styleUrl: './app-layout-horizontal.component.less'
})
export class AppLayoutHorizontalComponent {

}
