import { Component } from '@angular/core';
import { PageUtilBaseComponent } from '../page-util.component';

@Component({
  selector: 'page-coming-soon',
  templateUrl: './page-coming-soon.component.html',
  styleUrl: './page-coming-soon.component.less'
})
export class PageComingSoonComponent extends PageUtilBaseComponent {

  onNoticeBtnClick(): void {
    this._messageService.info(this._translateService.instant("util.comingsoon.info.notice"));
    this.onBackToHomeBtnClick();
  }

}
