import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { PageLoginBaseComponent } from '../page-login-base.component';

@Component({
  selector: 'page-login',
  templateUrl: './page-login.component.html',
  styleUrl: './page-login.component.less'
})
export class PageLoginComponent extends PageLoginBaseComponent {

  constructor() {
    super();
    this.validateForm = this._fb.group({
      username: [environment.hardCodeEmail,[Validators.required]],
      password: [environment.hardCodePassword,[Validators.required]],
      verifyCode: [environment.hardCodeVerificationCode,[Validators.required]],
      remember: [true]
    });
  }

  protected override getLoginName (): string {
    return this.validateForm.value.username || '';
  };

  onVerificationCodeLoginClick () {
    this._routingService.gotoLoginByVerificationCodePage();
  }

  override submitForm (): void {
    const { loginName,phoneNumber } = this.getUserLoginInfo();
    if (this.validateForm.valid) {
      const v = this.validateForm.value;
      this._authService.login(
        {
          username: loginName,
          password: v.password,verifyCode: v.verifyCode
        }
      ).subscribe(res => {
        if (res != null && res.id != null) {
          const casUser = res;
          this._storageService.setCasToken(casUser?.token);
          this._routingService.gotoUrlAfterLogin(this._redirectUrl);
        }
      });
    } else {
      console.error(this.validateForm.value);
    }
  }


}
