import { Component, ElementRef, inject } from '@angular/core';
import { AppLayoutService } from './app-layout.service';


@Component({ template: `` })
export abstract class PjLayoutComponent {

  protected abstract elmName(): string;

  protected _layoutService: AppLayoutService = inject(AppLayoutService);
  constructor(private _self: ElementRef) {
  }

  ngOnInit(): void {
    if (this._self.nativeElement.tagName.startsWith('PJ-')) {
      this._layoutService.setLayoutElmRef(this.elmName(), this._self);
    }
  }
}