import { Component,EventEmitter,forwardRef,inject,Input,Output } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { interval,Subscription } from 'rxjs';

@Component({
  selector: 'pj-verification-code',
  templateUrl: './pj-verification-code.component.html',
  styleUrl: './pj-verification-code.component.less',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PjVerificationCodeComponent),
    multi: true,
  }]
})
export class PjVerificationCodeComponent implements ControlValueAccessor {

  private _translateSerivce: TranslateService = inject(TranslateService);
  @Input()
  disabled: boolean = false;

  @Input()
  intervalTime: number = 60;  //单位： 秒

  @Output()
  codeRequest: EventEmitter<void> = new EventEmitter();

  verificationCode: string = '';

  private _label?: string;
  private _disableClick: boolean = false;

  private _countDown?: Subscription;

  ngOnInit(): void {
    this._translateSerivce.get('component.verificationcode.btn.label').subscribe(v => this._label = v);
  }

  ngOnDestroy (): void {
    if (this._countDown != null) {
      this._countDown.unsubscribe();
    }
  }

  onFetchVerificationCodeBtnClick (): void {
    if (this._disableClick || this.disabled) {
      return;
    }
    this.codeRequest.emit();

  }

  public startCountDown (): void {
    if (this._disableClick || this.disabled) {
      return;
    }
    this._countDown = interval(1000).subscribe(t => {
      if (t < this.intervalTime) {
        this._disableClick = true;
        this._label = (this.intervalTime - t) + 's';
      } else {
        this._disableClick = false;
        this._label = this._translateSerivce.instant("component.verificationcode.btn.label");
        this._countDown!.unsubscribe();
      }
    });
  }

  isBtnDisabled (): boolean {
    return this._disableClick;
  }

  onNgModelChange (code: string): void {
    this.verificationCode = code;
    if (this._onChanged != null) {
      this._onChanged(code);
    }
  }

  getLabel (): string {
    return this._label || '';
  }

  private _onChanged?: Function;
  private _onTouched?: Function;
  writeValue (obj: string): void {
    this.verificationCode = obj;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnChange (fn: any): void {
    this._onChanged = fn;
  }
  registerOnTouched (fn: any): void {
    this._onTouched = fn;
  }
}
