import { Component } from '@angular/core';
import { LayoutElmName } from '../app-layout.service';
import { PjLayoutComponent } from '../pj-layout.component';

@Component({
  selector: 'pj-layout-navigation',
  template: `<div></div>`
})
export class PjLayoutNavigationComponent extends PjLayoutComponent {
  protected override elmName(): string {
    return LayoutElmName.NAVBAR;
  }
}
