import { Component } from '@angular/core';
import { PageRegisterBaseComponent } from '../page-register-base.compinent';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'page-register',
  templateUrl: './page-register.component.html',
  styleUrl: './page-register.component.less'
})
export class PageRegisterComponent extends PageRegisterBaseComponent {

  registerForm!: FormGroup;
}
