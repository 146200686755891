import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { PjIconModule } from 'src/component/pj-icon/pj-icon.module';
import { environment } from 'src/environments/environment';
import { PageError404Component } from 'src/page/page-util/page-error-404/page-error-404.component';
import { activeGuard, deactivateGuard } from 'src/service/polarj.guard';
import { PathName } from 'src/service/routing.service';
import { AppLayoutHorizontalComponent } from './app-layout-horizontal/app-layout-horizontal.component';
import { AppLayoutVerticalComponent } from './app-layout-vertical/app-layout-vertical.component';
import { PjLayoutFooterComponent } from './pj-layout-footer/pj-layout-footer.component';
import { PjLayoutHeaderComponent } from './pj-layout-header/pj-layout-header.component';
import { PjLayoutNavigationComponent } from './pj-layout-navigation/pj-layout-navigation.component';
import { PjButtonModule } from 'src/component/pj-button/pj-button.module';

const routes: Routes = [{
  path: '', pathMatch: 'full', redirectTo: environment.mainComponentPath
}, {
  path: PathName.TEST, canActivate: [activeGuard], canDeactivate: [deactivateGuard],
  loadChildren: () => import('src/page/page-test/page-test.module').then(m => m.PageTestModule)
}, {
  path: PathName.DATA_MANAGE, canActivate: [activeGuard], canDeactivate: [deactivateGuard],
  loadChildren: () => import('src/page/page-md-dm/page-md-dm.module').then(m => m.PageMdDmModule)
}, {
  path: PathName.ERROR404, component: PageError404Component
}];
@NgModule({
  declarations: [
    AppLayoutVerticalComponent,
    AppLayoutHorizontalComponent,
    PjLayoutHeaderComponent,
    PjLayoutFooterComponent,
    PjLayoutNavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
    NzLayoutModule,

    PjIconModule,
    PjButtonModule,
    environment.projectModule
  ],
  exports: [
    AppLayoutVerticalComponent,
    AppLayoutHorizontalComponent,
    PjLayoutHeaderComponent,
    PjLayoutFooterComponent,
    PjLayoutNavigationComponent
  ]
})
export class AppLayoutModule { }
