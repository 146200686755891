<nz-input-group [nzPrefix]="verifyIcon" nzSize="large" [nzSuffix]="suffixTemplateInfo">
  <input type="text" [disabled]="disabled" nz-input [ngModel]="verificationCode"
    (ngModelChange)="onNgModelChange($event)" placeholder="{{'component.verificationcode.placeholder' | translate}}" />
</nz-input-group>

<ng-template #suffixTemplateInfo>
  <pj-std-button btnType="link" size="small" [disabled]="isBtnDisabled()" (click)="onFetchVerificationCodeBtnClick()"
    [label]="getLabel()">
  </pj-std-button>
</ng-template>

<ng-template #verifyIcon>
  <pj-icon class="icon" iconName="gpp_good"></pj-icon>
</ng-template>