import { Component,EventEmitter,forwardRef,Input,Output } from '@angular/core';
import { ControlValueAccessor,NG_VALUE_ACCESSOR } from '@angular/forms';
import { PjDirectionEnum,PjDirectionType,PjProperty,PjSelectionItem } from 'src/component/component.type';
import { HelperService } from 'src/service/helper.service';

@Component({
  selector: 'pj-checkbox',
  templateUrl: './pj-checkbox.component.html',
  styleUrl: './pj-checkbox.component.less',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PjCheckboxComponent),
    multi: true,
  }]
})
export class PjCheckboxComponent implements ControlValueAccessor {

  @Input()
  disabled: boolean = false;

  @Input()
  checkItem?: PjSelectionItem;

  @Input()
  direction: PjDirectionType = 'horizontal';

  @Input()
  hideDescriptionWhenChecked: boolean = true;

  @Output()
  selectionChanged: EventEmitter<boolean> = new EventEmitter();

  showDescription(): boolean {
    if (HelperService.hasStringValue(this.checkItem?.description)) {
      if (this.hideDescriptionWhenChecked && HelperService.booleanValue(this.checkItem?.selected)) {
        return false;
      }
      return true;
    }
    return false;
  }

  private _directionClass: PjProperty = {
    [PjDirectionEnum.H]: 'flex flex-row flex-wrap items-center gap-2',
    [PjDirectionEnum.V]: 'flex flex-col items-start gap-2',
  }

  onCheckboxClick(event: Event): void {
    event.stopPropagation();
    if (this.checkItem == null || this.disabled) {
      return;
    }
    this.checkItem.selected = !this.checkItem.selected;
    this.onCheckedChanged(this.checkItem.selected);
  }
  getDirectionClass(): string {
    return this._directionClass[this.direction];
  }

  onCheckedChanged(checked: boolean): void {
    if (this.disabled) {
      return;
    }
    if (this._onTouched) {
      this._onTouched();
    }
    this.selectionChanged.emit(checked);
    this._checked = checked;
    if (this._onChanged) {
      this._onChanged(this._checked);
    }
  }

  private _checked?: boolean;
  private _onChanged?: Function;
  private _onTouched?: Function;
  writeValue(obj: boolean): void {
    this._checked = obj;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  registerOnChange(fn: any): void {
    this._onChanged = fn;
    if (this._onChanged != null && this._checked != null) {
      this._onChanged(this._checked);
    }
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
