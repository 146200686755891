import { Component } from '@angular/core';
import { LayoutElmName } from '../app-layout.service';
import { PjLayoutComponent } from '../pj-layout.component';

@Component({
  selector: 'pj-layout-footer',
  template: `<div></div>`
})
export class PjLayoutFooterComponent extends PjLayoutComponent {

  protected override elmName(): string {
    return LayoutElmName.FOOTER;
  }

}
