<div class="util_body">
  <div class="flex flex-col gap-4 w-full">
    <h1 class="text-2xl font-bold">{{ 'util.comingsoon.title' | translate }}</h1>
    <div class="text-xl">{{ 'util.comingsoon.content' | translate }}</div>
    <div class="flex flex-row gap-4 items-center">
      <pj-text-input iconName="email" class="w-2/3"></pj-text-input>
      <pj-std-button btnType="filled" label="{{'util.comingsoon.btn.notice.label' | translate}}"
        (click)="onNoticeBtnClick()">
      </pj-std-button>
    </div>
    <pj-std-button btnType="filled" class="self-start" label="{{'util.btn.back.label' | translate}}"
      (click)="onBackToHomeBtnClick()">
    </pj-std-button>
  </div>
</div>