import { InjectionToken,Injector } from '@angular/core';
import { ScannerQRCode,ScannerQRCodeService } from './scanner-qrcode.service';
export const ScannerFactoryToken = new InjectionToken<ScannerQRCode>('ScannerFactoryToken');
export function ScannerFactory (injector: Injector) {
  const userAgent = navigator.userAgent || navigator.vendor;

  // if (/android/i.test(userAgent)) {
  //   return injector.get(ScannerQRCodeService);
  // } else if (/iPad|iPhone|iPod/.test(userAgent)) {
  //   return injector.get(BarcodeAndQrScannerService);
  // } else {
  //   return injector.get(ScannerQRCodeService);  // 默认返回Android服务
  // }
  return injector.get(ScannerQRCodeService);
}
