import { Component } from "@angular/core";
import { timer } from "rxjs";
import { environment } from "src/environments/environment";
import { PjPageComponent } from "../page-component";


@Component({ template: `` })
export abstract class PageAuthBaseComponent extends PjPageComponent {

  override ngOnInit(): void {
    super.ngOnInit();
    timer(100).subscribe(() => {
      this._appLayoutService.setHeaderVisible(false);
      this._appLayoutService.setFooterVisible(false);
      this._appLayoutService.setNavigationVisible(false);
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    timer(100).subscribe(() => {
      this._appLayoutService.setHeaderVisible(true);
      this._appLayoutService.setFooterVisible(environment.enableFooter);
      this._appLayoutService.setNavigationVisible(true);
    });
  }
}
