export const serverContextPath: string = '';
export const CASConfig = {
  moduleServiceCode: 'wms',
  protocal: 'https://',
  hostName: 'api.polarj.com',
  port: '443'
  // protocal: 'http://',
  // hostName: '192.168.11.221',
  // port: '8099'
};
