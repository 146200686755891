import { CommonModule } from '@angular/common';
import { AfterViewInit,Component,EventEmitter,inject,Input,OnDestroy,Output,ViewChild } from '@angular/core';
import { NgxScannerQrcodeComponent,NgxScannerQrcodeModule,ScannerQRCodeConfig,ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { PjButtonModule } from "src/component/pj-button/pj-button.module";
import { ScannerFactoryToken } from 'src/service/scanner-factory';

@Component({
  selector: 'pj-m-full-container',
  standalone: true,
  imports: [CommonModule,PjButtonModule,NgxScannerQrcodeModule],
  templateUrl: './pj-m-full-container.component.html',
  styleUrl: './pj-m-full-container.component.less'
})
export class pjMFullContainerComponent implements AfterViewInit,OnDestroy {
  ngOnDestroy (): void {
    // throw new Error('Method not implemented.');
  }


  scanService = inject(ScannerFactoryToken);
  // onEvent ($event: ScannerQRCodeResult[],_t8: NgxScannerQrcodeComponent) {
  //   throw new Error('Method not implemented.');
  // }
  @Input()
  showContainer = false;

  @Input()
  config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth
      },
    },

  };
  @Output()
  backBtnEmitter = new EventEmitter();
  @Output()
  eventEmitter = new EventEmitter<{
    event: ScannerQRCodeResult[],action: NgxScannerQrcodeComponent;
  }>();
  @ViewChild('action')
  action!: NgxScannerQrcodeComponent;

  onEvent ($event: ScannerQRCodeResult[],action: NgxScannerQrcodeComponent) {
    this.scanService.onEvent?.($event,action);
    this.eventEmitter.emit({
      event: $event,
      action
    });
  }
  onBackBtnClick () {
    this.backBtnEmitter.emit();
  }

  ngAfterViewInit (): void {
    this.scanService.setupScanner?.(this.action);
    console.log('pj-m-full-container.component >>>',this.action);

    // this.scanService.scan();
  }
}
