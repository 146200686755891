import { ComponentRef, ElementRef, EventEmitter, inject, Injectable, Type } from "@angular/core";
import { BehaviorSubject, Observable, timer } from "rxjs";
import { PjKeyValue } from "src/component/component.type";
import { ComponentService } from "src/service/component.service";
import { HelperService } from "src/service/helper.service";
import { AppLayoutConfig } from "./app-layout.config";

@Injectable({ providedIn: 'root' })
export class AppLayoutService {
  private _layoutElmRef: PjKeyValue<ElementRef> = {};
  private _layoutComponentRef: PjKeyValue<ComponentRef<any>> = {};
  private _compService: ComponentService = inject(ComponentService);
  private _layoutCfg: AppLayoutConfig = new AppLayoutConfig();
  private _layoutConfig$: BehaviorSubject<AppLayoutConfig> = new BehaviorSubject(this._layoutCfg);
  private _layoutElmChanged: EventEmitter<string> = new EventEmitter();

  layoutConfig(): Observable<AppLayoutConfig> {
    return this._layoutConfig$;
  }

  layoutElmChanged(): EventEmitter<string> {
    return this._layoutElmChanged;
  }
  setHeaderVisible(visible: boolean): void {
    this._layoutCfg.headerVisible = visible;
    timer(200).subscribe(() => this._layoutConfig$.next(this._layoutCfg));
  }

  setFooterVisible(visible: boolean): void {
    this._layoutCfg.footerVisible = visible;
    timer(200).subscribe(() => this._layoutConfig$.next(this._layoutCfg));
  }

  setNavigationVisible(visible: boolean): void {
    this._layoutCfg.navigationVisible = visible;
    timer(200).subscribe(() => this._layoutConfig$.next(this._layoutCfg));
  }

  setLayoutElmRef(elmName: string, elm: ElementRef): void {
    if (HelperService.hasStringValue(elmName) && elm != null) {
      this._layoutElmRef[elmName] = elm;
    }
  }

  getLayoutComponent(elmName: string): ComponentRef<any> | null {
    return this._layoutComponentRef[elmName];
  }

  attachHeader(componentType: Type<any>, data?: any): ComponentRef<any> | null {
    return this._attachLayoutComponent(LayoutElmName.HEADER, componentType, data);
  }

  detachHeader(): void {
    this._detachLayoutComponent(LayoutElmName.HEADER);
  }

  attachFooter(componentType: Type<any>, data?: any): ComponentRef<any> | null {
    return this._attachLayoutComponent(LayoutElmName.FOOTER, componentType, data);
  }

  detachFooter(): void {
    this._detachLayoutComponent(LayoutElmName.FOOTER);
  }

  attachNavigation(componentType: Type<any>, data?: any): ComponentRef<any> | null {
    return this._attachLayoutComponent(LayoutElmName.NAVBAR, componentType, data);
  }

  detachNavigation(): void {
    this._detachLayoutComponent(LayoutElmName.NAVBAR);
  }

  private _attachLayoutComponent(elmName: string, componentType: Type<any>, componentData?: any): ComponentRef<any> | null {
    const outerElmRef: ElementRef = this._layoutElmRef[elmName];
    if (outerElmRef == null) {
      return null;
    }
    outerElmRef.nativeElement.innerHTML = '';
    const preCompRef = this._layoutComponentRef[elmName];
    if (preCompRef != null && preCompRef.componentType != componentType) {
      this._compService.detachView(preCompRef);
    }
    this._layoutComponentRef[elmName] = this._compService.attachView(componentType, componentData, outerElmRef.nativeElement);
    this._layoutElmChanged.emit(elmName);
    return this._layoutComponentRef[elmName];
  }

  private _detachLayoutComponent(elmName: string): void {
    const outerElmRef: ElementRef = this._layoutElmRef[elmName];
    if (outerElmRef == null) {
      return;
    }
    const preCompRef = this._layoutComponentRef[elmName];
    if (preCompRef != null) {
      this._compService.detachView(preCompRef);
    }
  }
}

export const LayoutElmName = {
  HEADER: 'header',
  TOOLBAR: 'toolbar',
  NAVBAR: 'navbar',
  FOOTER: 'footer',
};
