<div class="flex flex-col items-center gap-2 justify-center">
  <div class="p-4 inline-flex">
    <img style="width: 382px" [src]="getImageUrl()" />
  </div>
  <div class="flex flex-col gap-4 p-8 shadow-lg border-solid rounded-lg border">
    <h3 class="text-2xl font-bold self-start">{{getTitle()}}</h3>
    <form nz-form [formGroup]="validateForm" class="flex flex-col gap-2 w-[320px]">
      <nz-form-item>
        <nz-form-control nzErrorTip="请输入手机号！">
          <pj-text-input iconName="phone" placeHolder="手机号" formControlName="phoneNumber">
          </pj-text-input>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-control nzErrorTip="请输入验证码！">
          <pj-verification-code formControlName="verifyCode" (codeRequest)="onFetchVerificationCodeBtnClick()">
          </pj-verification-code>
        </nz-form-control>
      </nz-form-item>

      <pj-std-button class="self-center w-1/2" label="登录" btnType="filled" (click)="submitForm()"></pj-std-button>
    </form>
    <!-- <div class="self-center w-1/2 flex flex-row items-center">
      <div>或</div>
      <pj-std-button btnType="link" label="账号密码登录" (click)="onAccountLoginClick()"></pj-std-button>
    </div> -->
  </div>
</div>

<ng-template #passwordIcon>
  <pj-icon class="icon" iconName="lock"></pj-icon>
</ng-template>