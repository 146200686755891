import { Injectable } from '@angular/core';
import {
  NgxScannerQrcodeComponent,
  ScannerQRCodeConfig,
  ScannerQRCodeResult,
} from 'ngx-scanner-qrcode';
import { Observable, Subject, debounceTime } from 'rxjs';
export interface ScannerQRCode {
  cancelScan: () => void;
  scanResult: () => Observable<ScannerResult>;
  hasCamera: () => boolean;
  scan: () => void;

  setupScanner?: (action: NgxScannerQrcodeComponent) => void;
  onEvent?: (e: ScannerQRCodeResult[], action?: any) => void;
}
interface ScannerResult {
  scanResult: string;
  errMessage: string;
}
export enum ScannerHandlerEvent {
  start = 'start',
  stop = 'stop',
}
@Injectable({
  providedIn: 'root',
})
export class ScannerQRCodeService implements ScannerQRCode {
  private _scanResultChange$ = new Subject<ScannerResult>();
  private _hasCamera: boolean = false;

  scanResultChange$ = this._scanResultChange$
    .asObservable()
    .pipe(debounceTime(200));

  // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#front_and_back_camera
  public config: ScannerQRCodeConfig = {
    constraints: {
      video: {
        width: window.innerWidth,
      },
    },
  };

  // @ViewChild('action') action!: NgxScannerQrcodeComponent;
  action!: NgxScannerQrcodeComponent;

  public percentage = 80;
  public quality = 100;

  // constructor(private qrcode: NgxScannerQrcodeService) {
  //   this.scan();
  // }
  setupScanner(action: NgxScannerQrcodeComponent) {
    setTimeout(() => {
      this.action = action;
    });
  }
  hasCamera: () => boolean = () => {
    return this.action !== undefined;
  };
  scan: () => void = () => {
    if (!this.action.isStart) {
      this.handle(this.action, ScannerHandlerEvent.start);
    }
  };
  cancelScan() {
    if (this.action.isStart) {
      this.handle(this.action, ScannerHandlerEvent.stop);
      this._sendResult([], 'Ignored');
    }
  }
  scanResult(): Observable<ScannerResult> {
    return this._scanResultChange$;
  }

  public onEvent(e: ScannerQRCodeResult[], action?: any): void {
    // e && action && action.pause();
    console.log(e);
    this._sendResult(e, '');
  }

  public handle(action: any, fn: string): void {
    const playDeviceFacingBack = (devices: any[]) => {
      // front camera or back camera check here!
      const device = devices.find((f) =>
        /后置相机|back|rear|environment/gi.test(f.label)
      ); // Default Back Facing Camera
      action.playDevice(device ? device.deviceId : devices[0].deviceId);
    };
    if (fn === 'start') {
      action[fn](playDeviceFacingBack).subscribe(
        (r: any) => console.log(fn, r),
        alert
      );
    } else {
      action[fn]().subscribe((r: any) => console.log(fn, r), alert);
    }
  }

  private _sendResult(e: ScannerQRCodeResult[], errMessage: string) {
    this._scanResultChange$.next({
      scanResult: e[0]?.value ?? '',
      errMessage: errMessage,
    });
  }

  // public onDowload (action: NgxScannerQrcodeComponent) {
  //   action.download().subscribe(console.log,alert);
  // }

  // public onSelects (files: any) {
  //   this.qrcode.loadFiles(files,this.percentage,this.quality).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
  //     this.qrCodeResult = res;
  //   });
  // }

  // public onSelects2 (files: any) {
  //   this.qrcode.loadFilesToScan(files,this.config,this.percentage,this.quality).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
  //     console.log(res);
  //     this.qrCodeResult2 = res;
  //   });
  // }

  // public onGetConstraints () {
  //   const constrains = this.action.getConstraints();
  //   console.log(constrains);
  // }

  // public applyConstraints () {
  //   const constrains = this.action.applyConstraints({
  //     ...this.action.getConstraints(),
  //     width: 510
  //   });
  //   console.log(constrains);
  // }
}
