import { Directive,ElementRef,inject,Input,Renderer2 } from "@angular/core";


@Directive({
  standalone: true,
  host: {
    '(focus)': '_onFocus($event)',
  },
  selector: '[pjTextInputAutoPanel]'
})
export class PjInputAutoPanelDirective {
  // 移动端focus状态时是否自动弹出输入法面板
  @Input() disableAutoPanel: boolean = false;
  private _host = inject(ElementRef<HTMLInputElement>);
  private _renderer = inject(Renderer2);

  private _onFocus (event: FocusEvent) {
    if (!this.disableAutoPanel) {
      return;
    }
    this._renderer.setAttribute(this._host.nativeElement,'readonly','readonly');
    setTimeout(() => {
      this._renderer.removeAttribute(this._host.nativeElement,'readonly');
    },200);
  }
}
