import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { isEmpty } from 'lodash';
import { FileUploader, Headers } from 'ng2-file-upload';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject, Observable, Subject, catchError, concatMap, filter, map, of, startWith } from 'rxjs';
import { APPCONSTANT } from 'src/app/app.constant';
import { serverContextPath } from 'src/environments/service.config';
import {
  ClientRequest, HttpCallParameter, ModuleServiceConfig, PagingData, PostCallParameter, ServerError, ServerResponse, StatusLevel
} from './httpcall.type';
import { StorageService } from './storage.service';
export const ERROR_MSG = {
  UNKNOWN_ERROR: 'Unknow error.',
  INVALID_TOKEN: 'Invalid token.'
};
enum FileTypeAcceptStringMap {
  'pdf' = 'application/pdf',
  'xml' = 'application/xml',
  'xlsx' = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'xls' = 'application/vnd.ms-excel',
  'docx' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'doc' = 'application/msword',
  'json' = 'application/json',
  'txt' = 'text/plain',
  'png' = 'image/png',
  'jpg' = 'image/jpeg',
  'jpeg' = 'image/jpeg',
  'sql' = 'text/plain',
  'zip' = 'application/zip',
}

enum CanOpenFileType {
  'pdf' = 'application/pdf',
  'xml' = 'application/xml',
  'png' = 'image/png',
  'jpg' = 'image/jpeg',
  'jpeg' = 'image/jpeg',
  'txt' = 'text/plain',
  'json' = 'application/json',
}

type FileType = keyof typeof FileTypeAcceptStringMap;

@Injectable({ providedIn: 'root' })
export class RestfulService {
  private _httpClient: HttpClient = inject(HttpClient);
  private _fileSaverService: FileSaverService = inject(FileSaverService);
  private _storageService: StorageService = inject(StorageService);

  constructor(
  ) {
    this.globalLoading.subscribe((start) => {
      if (start) {
        this.startHttpCount++;
      } else {
        this.startHttpCount--;
      }

      if (this.startHttpCount > 0 && !this.isLoading) {
        this.isLoading = true;
      } else if (this.startHttpCount === 0 && this.isLoading) {
        this.isLoading = false;
      }
    });
  }

  private startHttpCount: number = 0;
  private isLoading = false;
  // private moduleService: PjModuleService[] = [];
  protected globalLoading = new Subject<boolean>(); // true代表请求开始，false代表结束

  private _handleHttpData(
    httpData$: Observable<any>,
    httpCallParameter: HttpCallParameter,
  ): Observable<any> {
    const that = this;
    const isLoading = httpCallParameter.loading;
    let ob$ = httpData$.pipe(
      startWith(() => {
        isLoading && that.globalLoading.next(true);
      }),
      map(v => {
        isLoading && this.globalLoading.next(false);
        return v.body;
      }),
      catchError(error => {
        isLoading && this.globalLoading.next(false);
        return that._handleError(error);
      }),
    );// as Observable<ServerResponse<T>>;
    return this._handleServerResponse(ob$,
      httpCallParameter.enableErrorMsg,
      httpCallParameter.singleData,
      httpCallParameter.pageData
    );
  }

  // 对应http的get method
  getCall(urlPart: HttpUrlPart): Observable<any> {
    const that = this;
    this._saveLastAccessTime(urlPart);

    const httpData$ = that._getBackendUrl(urlPart).pipe(
      concatMap((url) => {
        return that._httpClient.get(url, that._generateHttpCallOptions(urlPart.operationCode));
      }),
    );

    return that._handleHttpData(httpData$, urlPart);
  }

  // 对应http的post method
  postCall(urlPart: HttpUrlPart, para: any): Observable<any> {
    const that = this;
    this._saveLastAccessTime(urlPart);
    let jsonParaString!: string;
    const clientReq: ClientRequest = new ClientRequest();
    clientReq.nonceToken = that._generateNonceToken();
    clientReq.data = para;
    if (urlPart.captchaResp != null) {
      clientReq.captchaResp = urlPart.captchaResp;
    }
    jsonParaString = JSON.stringify(clientReq);

    const httpData$ = that._getBackendUrl(urlPart).pipe(
      concatMap((url) => {
        return that._httpClient.post(
          url,
          jsonParaString,
          that._generateHttpCallOptions(urlPart.operationCode),
        );
      }),
    );
    return that._handleHttpData(httpData$, urlPart);
  }

  // 对应http的put method
  putCall(urlPart: HttpUrlPart, para: any): Observable<any> {
    const that = this;
    this._saveLastAccessTime(urlPart);
    let jsonParaString!: string;
    const clientReq: ClientRequest = new ClientRequest();
    clientReq.nonceToken = that._generateNonceToken();
    clientReq.data = para;
    if (urlPart.captchaResp != null) {
      clientReq.captchaResp = urlPart.captchaResp;
    }
    jsonParaString = JSON.stringify(clientReq);

    const httpData$ = that._getBackendUrl(urlPart).pipe(
      concatMap((url) => {
        return that._httpClient.post(
          url,
          jsonParaString,
          that._generateHttpCallOptions(urlPart.operationCode),
        );
      }),
    );

    return that._handleHttpData(httpData$, urlPart);
  }

  // 对应http的delete method
  // 目前只用于删除指定id的业务模型
  deleteCall(urlPart: HttpUrlPart): Observable<any> {
    const that = this;
    this._saveLastAccessTime(urlPart);

    const httpData$ = that._getBackendUrl(urlPart).pipe(
      concatMap((url) => {
        return that._httpClient.delete(url, that._generateHttpCallOptions(urlPart.operationCode));
      }),
    );

    return that._handleHttpData(httpData$, urlPart);
  }

  downloadPostCall(urlPart: HttpUrlPart, para?: any, fileName?: string, fileType?: FileType, openFile?: boolean): void {
    const that = this;
    this._saveLastAccessTime(urlPart);
    if (!fileType) {
      fileType = fileName?.substring(
        fileName.indexOf('.') + 1,
        fileName.length,
      ) as FileType;
      fileType = fileType ? fileType : 'pdf';
    }
    const downloadHeaders: HttpHeaders = new HttpHeaders();

    downloadHeaders.append('Accept', FileTypeAcceptStringMap[fileType]);

    const options = that._generateHttpCallOptions(urlPart.operationCode, downloadHeaders);

    let jsonParaString!: string;
    const clientReq: ClientRequest = new ClientRequest();
    clientReq.nonceToken = that._generateNonceToken();
    clientReq.data = para;
    if (urlPart.captchaResp != null) {
      clientReq.captchaResp = urlPart.captchaResp;
    }
    jsonParaString = JSON.stringify(clientReq);

    that._getBackendUrl(urlPart).pipe(
      concatMap((url) => {
        return that._httpClient.post(url, jsonParaString, {
          observe: 'response',
          responseType: 'blob',
          headers: options.headers,
          withCredentials: options.withCredentials,
        });
      }),
    ).subscribe((res) => {
      const curFileName = this._parseResHeaderFileName(res.headers, fileName);
      if (res.body != undefined) {
        const blob = new File([res.body], curFileName, {
          type: FileTypeAcceptStringMap[fileType],
        });
        if (openFile && fileType in CanOpenFileType) {
          // 这两行代码是从浏览器直接打开从服务器得到的文件，但是一直没有找到显示文件名的方案。
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        } else {
          this._fileSaverService.save(blob, curFileName);
        }
      }
    });
  }

  downloadCall(urlPart: HttpUrlPart, fileName?: string, fileType?: FileType, openFile: boolean = false, successCallback: () => void = () => { }): void {
    const that = this;
    this._saveLastAccessTime(urlPart);
    if (fileType == null) {
      if (fileName != undefined) {
        fileType = fileName.substring(
          fileName.indexOf('.') + 1,
          fileName.length,
        ) as FileType;
      }
      fileType = fileType ? fileType : 'pdf';
    }
    const downloadHeaders: HttpHeaders = new HttpHeaders();

    downloadHeaders.append('Accept', FileTypeAcceptStringMap[fileType]);

    const options = that._generateHttpCallOptions(urlPart.operationCode, downloadHeaders);

    that._getBackendUrl(urlPart).pipe(
      concatMap((url) => {
        return that._httpClient.get(url, {
          observe: 'response',
          responseType: 'blob',
          headers: options.headers,
          withCredentials: options.withCredentials,
        });
      }),
    ).subscribe((res) => {
      // window.open(that._getBackendUrl(urlPart));

      const curFileName = this._parseResHeaderFileName(res.headers, fileName);

      if (res.body != undefined) {
        const blob = new File([res.body], curFileName, {
          type: FileTypeAcceptStringMap[fileType!],
        });

        if (openFile) {
          // 这两行代码是从浏览器直接打开从服务器得到的文件，但是一直没有找到显示文件名的方案。
          const fileURL = URL.createObjectURL(blob);
          console.log(fileURL);
          window.open(fileURL);
        } else {
          // 目前先优先使用前端设定的文件名
          this._fileSaverService.save(blob, curFileName);
        }

        successCallback();
      }
    });
  }

  private _parseResHeaderFileName(headers: HttpHeaders, fileName?: string): string {
    // 目前没有设定前后段都没有文件名时的,默认文件名
    const contentDisposition = headers.get('Content-Disposition') || undefined;
    const headerFileName =
      contentDisposition && contentDisposition.split(';').find(item =>
        item.split('=').some((key) => key.trim() === 'filename'),
      )?.split('=').pop();
    return headerFileName || fileName || '';
  }

  //没有OSS的笨办法，部署OSS后或者有其他获取图片方法后，删除该方法--23/9/21川
  showDownloadImg(urlPart: HttpUrlPart, fileName: string, fileType?: FileType, openFile?: boolean): any {
    const that = this;
    this._saveLastAccessTime(urlPart);
    if (fileType == null) {
      fileType = fileName.substring(
        fileName.indexOf('.') + 1,
        fileName.length,
      ) as FileType;
      fileType = fileType ? fileType : 'pdf';
    }
    const downloadHeaders: HttpHeaders = new HttpHeaders();

    downloadHeaders.append('Accept', FileTypeAcceptStringMap[fileType]);

    const options = that._generateHttpCallOptions(urlPart.operationCode, downloadHeaders);

    return that._getBackendUrl(urlPart).pipe(
      concatMap((url) => {
        return that._httpClient.get(url, {
          responseType: 'blob',
          headers: options.headers,
          withCredentials: options.withCredentials,
        });
      }),
    );
  }

  uploadCall<T>(postCallPara: PostCallParameter, files: File[]): Observable<ServerResponse<T>> {
    const successItem$ = new BehaviorSubject<ServerResponse<T>>(new ServerResponse<T>());

    // const handlerUrl = this._getBackendUrl(postCallPara);
    const casToken = this._storageService.getCasToken();
    const uploadHeaders: Array<Headers> = new Array<Headers>();

    if (casToken != null && casToken.length > 0) {
      uploadHeaders.push({ name: HeaderName.CasToken, value: casToken });
      uploadHeaders.push({ name: HeaderName.OperationCode, value: postCallPara.operationCode ?? '' });
    }

    this._getBackendUrl(postCallPara).subscribe((url) => {
      const _fileUploader = new FileUploader({
        url: url,
        removeAfterUpload: true,
        headers: uploadHeaders,
        additionalParameter: postCallPara.para ?? {},
      });
      // _fileUploader.onProgressItem = function (fileItem: FileItem, progress: number): void {
      //   console.log('Uploading: ' + fileItem.file.name + ', ' + progress + '%');
      // };
      _fileUploader.onProgressAll = function (progress: number): void {
        console.log(progress);
      };

      _fileUploader.onSuccessItem = (item, response, status, headers) => {
        successItem$.next(JSON.parse(response));
      };

      _fileUploader.onCompleteAll = function (): void {
        console.log('Uploaded!');
      };

      _fileUploader.addToQueue(files);

      if (!isEmpty(url)) {
        _fileUploader.uploadAll();
      }
    });

    return successItem$;
  }

  private _saveLastAccessTime(urlPart: HttpUrlPart): void {
    if (urlPart.operateAutomatically) {
      return;
    }
    this._storageService.setLocalItem(
      APPCONSTANT.LOCAL_STORAGE_ITEM_NAME.LAST_ACCESS_TIME,
      new Date().getTime(),
    );
  }
  private _getBackendUrl(urlPart: HttpUrlPart): Observable<string> {
    if (!isEmpty(urlPart.moduleServiceConfig.moduleServiceCode)) {
      // 如果传入 moduleServiceName 使用原本的方式传输
      return of(
        `${urlPart.moduleServiceConfig.protocal
        }${urlPart.moduleServiceConfig.hostName
        }:${urlPart.moduleServiceConfig.port
        }${serverContextPath === '' ? '' : '/' + serverContextPath
        }${urlPart.moduleServiceConfig.moduleServiceCode ? '/' + urlPart.moduleServiceConfig.moduleServiceCode : ''}${urlPart.modelCode ? '/' + urlPart.modelCode : ''
        }${urlPart.requestMappingString ? '/' + urlPart.requestMappingString : ''
        }${urlPart.urlPara ? '?' + urlPart.urlPara : ''}`,
      );
    }
    return of('');
  }

  private _generateHttpCallOptions(operationCode?: string, optionHeaders?: HttpHeaders): any {
    const casToken = this._storageService.getCasToken();
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append(HeaderName.ContentType, 'application/json');
    if (casToken != null && casToken.length > 0) {
      headers = headers.append(HeaderName.OperationCode, operationCode || '');
      headers = headers.append(HeaderName.CasToken, casToken);
    }
    if (optionHeaders != null) {
      optionHeaders.keys().forEach((key) => {
        if (optionHeaders.get(key) != null) {
          const s: string = optionHeaders.get(key) as string;
          headers = headers.append(key, s);
        }
      });
    }
    return {
      headers: headers,
      observe: 'response',
      withCredentials: true,
    };
  }

  private _generateNonceToken(): string {
    return '123123123';
  }

  private _handleError<T>(error: HttpResponse<any>): Observable<ServerResponse<T> | null> {
    // 配置全局http状态码
    let serverResp: ServerResponse<T> | null;
    if (((error as any).error?.statusList?.length || 0) > 0) {
      serverResp = (error as any).error;
    } else {
      serverResp = new ServerResponse();//ServerResponse.generateErrorResponse(error.statusText);
      serverResp.dataList = [];
      serverResp.statusList = [];
      serverResp.statusList.push({
        level: StatusLevel.ERROR,
        code: ServerError.Authentication,
        desc: error.statusText || ERROR_MSG.UNKNOWN_ERROR
      });
    }
    return of(serverResp);
  }

  private _handleServerResponse(
    ob$: Observable<any>, enableErrorMsg?: boolean, oneData?: boolean, pageData?: boolean
  ): Observable<any> {
    let _mappedOb$: Observable<any>;
    if (oneData) {
      _mappedOb$ = ob$.pipe(map(response => this._handleOneData(response, enableErrorMsg)));
    } else if (pageData) {
      _mappedOb$ = ob$.pipe(map(response => this._handlePagingData(response, enableErrorMsg)));
    } else {
      _mappedOb$ = ob$.pipe(map(response => this._handleListData(response, enableErrorMsg)));
    }
    return _mappedOb$.pipe(filter(res => res != null));
  }

  private _handleOneData(
    serverResponse: ServerResponse<any>, enableErrorMsg?: boolean
  ): any {
    const hasError = this._hasError(serverResponse, enableErrorMsg);
    if (hasError) {
      return serverResponse;
    }
    if (!serverResponse.dataList) {
      return null;
    }
    if (serverResponse.dataList.length != 1) {
      // this._messageService.error('Got too much data.');
      return {};
    }
    return serverResponse.dataList[0];
  }

  private _handlePagingData(
    serverResponse: ServerResponse<any>, enableErrorMsg?: boolean
  ): PagingData<any> | null {
    const hasError = this._hasError(serverResponse, enableErrorMsg);
    if (hasError) {
      return serverResponse;
    }
    const pagingData = new PagingData();
    pagingData.pageIndex = (serverResponse.currentPageIndex || 0) + 1;
    pagingData.dataList = serverResponse.dataList;
    pagingData.pageSize = serverResponse.pageSize;
    pagingData.totalPages = serverResponse.totalPages;
    pagingData.totalRecords = serverResponse.totalRecords;
    return pagingData;
  }

  private _handleListData(
    serverResponse: ServerResponse<any>,
    enableErrorMsg?: boolean,
  ): Array<any> | null | ServerResponse<any> {
    const hasError = this._hasError(serverResponse, enableErrorMsg);
    if (hasError) {
      return serverResponse;
    }
    if (!serverResponse.dataList) {
      return [];
    }
    return serverResponse.dataList;
  }

  private _hasError(
    serverResponse: ServerResponse<any>, enableErrorMsg?: boolean
  ): boolean {
    if (!serverResponse) {
      return true;
    }
    let hasError = false;
    if (serverResponse.statusList && serverResponse.statusList.length > 0) {
      let msg = serverResponse.statusList[0].desc;
      serverResponse.statusList.forEach(status => {
        if (status.level == 'ERROR') {
          msg = status.desc;
          hasError = true;
        }
      });
      console.log('ALERT: ' + msg);
    }
    return hasError;
  }
}

const HeaderName = {
  CasToken: 'CAS_TOKEN',
  ContentType: 'Content-Type',
  OperationCode: 'Operation-Code'
};
// 后端的链接格式：
// http(s)://hostname:port/moduleServiceCode/modelCode/requestMappingString?urlPara
// moduleServiceCode: 对应Tomcat的context path，或者是Java项目pom.xml中的artifactId
// modelCode： Java代码中的Controller的注解RequestMapping中value对应的值，目前采用model的class名的全小写+'s'
// urlPara： key1=value1&key2=value2&...
interface HttpUrlPart {
  moduleServiceConfig: ModuleServiceConfig;
  modelCode: string;
  requestMappingString?: string;
  urlPara?: string;
  operationCode?: string;
  operateAutomatically?: boolean;
  captchaResp?: string;
}

