import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { PjIconModule } from 'src/component/pj-icon/pj-icon.module';
import { PjInputAutoPanelDirective } from 'src/directive/pj-input-auto-panel.directive';
import { pjMFullContainerComponent } from '../pj-m-full-container/pj-m-full-container.component';
import { PjTextInputComponent } from './pj-text-input.component';



@NgModule({
  declarations: [
    PjTextInputComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NzInputModule,
    PjIconModule,
    pjMFullContainerComponent,
    PjInputAutoPanelDirective
  ],
  exports: [
    PjTextInputComponent
  ]
})
export class PjTextInputModule { }
